<template>
  <div class="signpage_content">
    <div class="l_r_box">
      <!-- <div class="l_r_tab">
                <div class="l_r_tab_item" :class="[tabIndex==0?'l_r_tab_active':'']" @click.stop="changeTab(0)">登录</div>
                <div class="l_r_tab_item" :class="[tabIndex==1?'l_r_tab_active':'']" @click.stop="changeTab(1)">注册</div>
            </div> -->
      <div class="form_body">
        <div
          class="l_body flex_center"
          :class="[tabIndex == 0 ? '' : 'l_fade']"
          v-cloak
        >
          <!-- <el-form class="login_form_box" ref="loginForm" :rules="login_rules" :model="login_form" label-position="left" hide-required-asterisk>
                        <el-form-item class="form_item" label="账号" label-width="60px" prop="user_name">
                            <el-input placeholder="请输入手机号" v-model.number="login_form.user_name"></el-input>
                        </el-form-item>
                        <el-form-item class="form_item" label="密码" label-width="60px" prop="password">
                            <el-input placeholder="请输入密码" type="password" v-model="login_form.password"></el-input>
                        </el-form-item>
                        <span class="reset_pa" @click.stop="toResetPa">忘记密码 <i class="el-icon-question"></i></span>
                        <el-form-item class="form_item_btn flex_center">
                            <el-button class="form_btn" type="primary" :loading="loading" @click="toLogin">登录</el-button>
                        </el-form-item>
                    </el-form> -->
          <div class="wx_code" id="login_code"></div>
        </div>
        <!-- <div class="r_body flex_center" :class="[tabIndex==1?'r_fade':'']" v-cloak>
                    <img src="@assets/imgs/u30.png" class="wx_code_img" alt="">
                </div> -->
      </div>
    </div>
    <div class="adv_wrap">
      <div class="adv_title">{{adTitle}}</div>
      <el-carousel
        indicator-position="outside"
        height="350px"
        @change="changeSwiper"
      >
        <el-carousel-item v-for="item in alertAdData" :key="item.id">
          <el-image :src="item.ad_image">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { handleGetAlertAd } from "@/api/placeOrder";
export default {
  data() {
    // 手机号码格式正则匹配
    var validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1[3|4|5|7|8][0-9]\d{8}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      tabIndex: 0,
      // 登录表单字段
      login_form: {
        user_name: "",
        password: "",
      },
      // 登录表单验证规则
      login_rules: {
        user_name: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      alertAdData: [],
      adTitle: "",
    };
  },
  created() {
    // self_redirect:true,
    this.onGetAlertAd();
  },
  mounted() {
    this.$nextTick((_) => {
      // let origin = encodeURIComponent(
      //   window.location.origin + "/index.html#/loginSuccess"
      // );
      let origin ='https://www.fenghuayunshu.com/index.html#/loginSuccess'
      var obj = new WxLogin({
        id: "login_code",
        appid: "wx736b72daa0a481d0",
        scope: "snsapi_login",
        redirect_uri: origin,
        state: "wyt",
      });
    });
  },
  methods: {
    changeTab(key) {
      this.tabIndex = key;
    },
    toLogin() {
      this.loading = true;
      this.$store
        .dispatch("login", {})
        .then((datas) => {
          datas.data.user.user_sn = "user6018cd4a1512e";
          this.$storage.set("userData", datas.data.user);
          this.$router.replace("/");
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    changeSwiper(event) {
      console.log(event);
      this.adTitle=this.alertAdData[event].ad_title 
    },
    onGetAlertAd() {
      handleGetAlertAd().then((datas) => {
        console.log("广告", datas);
        if (datas.status == 0 && datas.data && Array.isArray(datas.data)) {
            this.alertAdData = datas.data;
           this.adTitle=this.alertAdData[0].ad_title 
        }
      });
    },
  },
};
</script>

<style lang="scss">
.signpage_content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 2px;
  min-height: 688px;
  height: 100vh;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.l_r_box {
  width: 420px;
  height: 420px;
  box-shadow: 2px 2px 10px 4px rgba(218, 217, 217, 0.8);
  background: #fff;
  margin-right: 20px;
}

.l_r_tab {
  width: 100%;
  height: 60px;
  display: flex;
  border-bottom: 1px solid #d9d9d9;
}

.l_r_tab_item {
  width: 50%;
  text-align: center;
  line-height: 60px;
  font-size: 15px;
  position: relative;
  cursor: pointer;
}

.l_r_tab_active::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  margin: auto;
  width: 60%;
  height: 2px;
  background: #272727;
  /* border-bottom: 2px solid #272727; */
}

.form_body {
  position: relative;
  width: 100%;
  // height: calc(420px - 60px);
  height: 420px;
  overflow: hidden;
}

.l_body {
  position: absolute;
  padding: 20px;
  width: 100%;
  height: 100%;
  transform: translateX(0%);
  transition: all 0.3s ease-in-out;
}

.r_body {
  position: absolute;
  padding: 20px;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
}

.l_fade {
  transform: translateX(-100%);
}

.r_fade {
  transform: translateX(0%);
}

.login_form_box {
  width: 80%;
}

.form_item {
  width: 100%;
}

.form_item_btn {
  width: 100%;
}

.form_item_btn .el-form-item__content,
.form_btn {
  width: 100%;
}

.text_code_item .el-form-item__content {
  display: flex;
  align-items: center;
}

.reset_pa {
  width: 100%;
  padding-bottom: 20px;
  text-align: right;
  font-size: 12px;
  display: block;
}

.reset_pa:hover {
  cursor: pointer;
}

.wx_code_img {
  width: auto;
  height: auto;
}

.adv_wrap {
  width: 320px;
  height: 420px;
  background: #f7f7f7;
  .adv_title {
    font-size: 15px;
    color: #333;
    line-height: 34px;
    text-align: center;
    font-weight: 600;
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 350px;
    margin: 0;
    text-align: center;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  .el-carousel__button {
    height: 12px;
    width: 12px;
    border-radius: 12px;
  }
}
</style>