import { axiosGet, axiosPost } from "@/api/request"
import apis from "@/api/apis"

export function handleGetTIme() {
    return axiosGet(apis.getTime)
}
// 获取可领取优惠券
export function handleGetCounpon(data) {
    return axiosGet(apis.couponCenter, data)
}
// 获取可使用优惠券
export function handleGetAbleCoupon(data) {
    return axiosPost(apis.getAbleCoupon, data)
}

export function handleGetCarType() {
    return axiosGet(apis.carType)
}
export function handleGetAlertAd() {
    return axiosGet(apis.alertAd)
}

// 常用路线
export function handleCommonRoute(data) {
    return axiosGet(apis.commonRoute, data)
}

// 删除常用路线
export function delCommonRoute(data) {
    return axiosPost(apis.deleteRooute, data)
}

// 常用货物名称
export function handleGetCargo(data) {
    return axiosGet(apis.cargoType, data)
}
// 获取智能选车的匹配车型
export function handleGetAutoCarType(data) {
    return axiosPost(apis.autoCarType, data)
}

// 下单叫车
export function handleSubmitOrder(data) {
    return axiosPost(apis.submitOrder, data);
}

// 获取备注列表
export function handleGetRemarks(data) {
    return axiosGet(apis.getRemarks, data)
}